<template>
  <v-dialog
    v-model="dialog"
    width="500"
  >
    <v-btn
      slot="activator"
      flat
      icon
    >
      <v-icon>
        info
      </v-icon>
    </v-btn>

    <v-card>
      <v-card-title
        class="headline"
        primary-title
      >
        Information
      </v-card-title>

      <v-card-text>
        <p class="breakWord">
          <u>Nom du client</u>: {{ client.name }}<br>
          <u>Créée le</u>: {{ client.createdAt | fullDate }}<br>
          <u>Admin</u>: {{ client.admin }}<br>
          <u>Clé d'API</u>: {{ client.APIKey }}
        </p>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          flat
          @click="dialog = false"
        >
          Fermer
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data() {
    return {
      dialog: false,
    };
  },
  computed: {
    client() { return this.$store.getters['auth/client']; },
  },
};
</script>

<style lang="scss">
.breakWord {
  word-break: break-word;
}
</style>
