/* eslint-disable no-param-reassign */
/* eslint-disable no-shadow */

import axios from 'axios';
import authService from '@/services/auth.service';

const state = {
  APIKey: localStorage.getItem('APIKey') || '',
  status: '',
  client: null,
};

const getters = {
  isLoggedIn: state => !!state.APIKey,
  authStatus: state => state.status,
  client: state => ({
    ...state.client,
    APIKey: state.APIKey,
  }),
};

const actions = {
  checkAPIKey: ({ commit }, APIKey) => new Promise((resolve, reject) => {
    commit('checkAPIKey');
    authService.login(APIKey)
      .then((res) => {
        if (res.data.client) {
          localStorage.setItem('APIKey', APIKey);
          commit('success', { APIKey, client: res.data.client });
          axios.defaults.headers.common.Authorization = APIKey;
          resolve(res.data.client);
        } else {
          commit('failed');
          localStorage.removeItem('APIKey');
          resolve(res.data.isValid);
        }
      })
      .catch((err) => {
        commit('error', err);
        localStorage.removeItem('APIKey');
        reject(err);
      });
  }),
  logout({ commit }) {
    return new Promise((resolve) => {
      commit('logout');
      localStorage.removeItem('APIKey');
      delete axios.defaults.headers.common.Authorization;
      resolve();
    });
  },
  me({ commit, state }) {
    return new Promise((resolve, reject) => {
      if (state.APIKey) {
        commit('checkAPIKey');
        authService.login(state.APIKey)
          .then((res) => {
            if (res.data.client) {
              localStorage.setItem('APIKey', state.APIKey);
              commit('success', { APIKey: state.APIKey, client: res.data.client });
              resolve(res.data.client);
            } else {
              commit('failed');
              localStorage.removeItem('APIKey');
              resolve(res.data.isValid);
            }
          })
          .catch((err) => {
            commit('error', err);
            localStorage.removeItem('APIKey');
            reject(err);
          });
      } else {
        resolve();
      }
    });
  },
};

const mutations = {
  checkAPIKey: (state) => {
    state.status = 'loading';
  },
  success: (state, payload) => {
    state.status = 'success';
    state.APIKey = payload.APIKey;
    state.client = payload.client;
  },
  failed: (state) => {
    state.status = 'failed';
    state.APIKey = '';
    state.client = null;
  },
  logout: (state) => {
    state.status = 'success';
    state.APIKey = '';
    state.client = null;
  },
  error: (state) => {
    state.status = 'error';
    state.APIKey = '';
    state.client = null;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
