<template>
  <v-app>
    <v-navigation-drawer
      persistent
      v-model="drawer"
      :clipped="true"
      enable-resize-watcher
      app
    >
      <v-toolbar flat class="transparent">
        <v-list class="pa-0">
          <v-list-tile>
            <v-list-tile-content>
              <v-list-tile-title>{{ client.name }}</v-list-tile-title>
            </v-list-tile-content>
          </v-list-tile>
        </v-list>
      </v-toolbar>
      <v-divider></v-divider>
      <v-list>
        <v-list-tile
          v-for="(item, i) in items"
          :key="i"
          :to="{path: item.link}"
          v-if="item.auth && authenticated"
        >
          <v-list-tile-action>
            <v-icon v-html="item.icon"></v-icon>
          </v-list-tile-action>
          <v-list-tile-content>
            <v-list-tile-title v-text="item.title"></v-list-tile-title>
          </v-list-tile-content>
        </v-list-tile>
      </v-list>
    </v-navigation-drawer>
    <v-toolbar
      :clipped-left="true"
      dark
      color="primary"
      app
    >
      <v-toolbar-side-icon
        v-if="authenticated"
        @click.stop="drawer = !drawer"
        color="whiteprimary"
      >
      </v-toolbar-side-icon>
      <router-link to="/">
        <v-toolbar-title class="white--text" v-text="title"></v-toolbar-title>
      </router-link>
      <v-spacer></v-spacer>
      <InformationDialog v-if="authenticated"></InformationDialog>
      <v-btn v-if="!authenticated" flat medium class="white--text" :to="{path: '/login'}">
        Se connecter
      </v-btn>
      <v-btn v-else icon @click="logout">
        <v-icon color="white">power_settings_new</v-icon>
      </v-btn>
    </v-toolbar>
    <v-content>
      <router-view/>
    </v-content>
    <v-footer app>
      <v-flex
        primary
        lighten-1
        py-3
        text-xs-center
        white--text
        xs12
      >
        <span>&copy;2019 - <strong>Docomatic client v{{version()}}</strong> - Copro Tech Team</span>
      </v-flex>
    </v-footer>
    <Snackbar></Snackbar>
  </v-app>
</template>

<script>
import Snackbar from '@/components/Snackbar.vue';
import InformationDialog from '@/components/InformationDialog.vue';
import { mapMutations } from 'vuex';
import { version } from '../package.json';

export default {
  name: 'App',
  components: {
    Snackbar,
    InformationDialog,
  },
  data() {
    return {
      drawer: false,
      items: [{
        icon: 'list',
        title: 'Liste des documents',
        link: '/list',
        auth: true,
      }, {
        icon: 'add',
        title: 'Nouveau document',
        link: '/new',
        auth: true,
      }, {
        icon: 'add',
        title: 'Convertir document',
        link: '/convert',
        auth: true,
      }],
      title: 'Docomatic',
    };
  },
  computed: {
    authenticated() { return this.$store.getters['auth/isLoggedIn']; },
    client() { return this.$store.getters['auth/client']; },
  },
  methods: {
    logout() {
      this.$store.dispatch('auth/logout')
        .then(() => {
          this.$router.push('/login');
        });
    },
    version() {
      if (process.env.VUE_APP_ENV === 'staging') {
        return '.staging';
      } else if (process.env.VUE_APP_ENV === 'development') {
        return '.dev';
      }
      return version;
    },
    goHome() {
      this.$router.push('/');
    },
    ...mapMutations({
      setSnack: 'snackbar/setSnack',
    }),
  },
  created() {
    this.$store.dispatch('auth/me').catch(() => {
      this.setSnack('Erreur.');
    });
  },
};
</script>

<style lang="scss">
.v-toolbar__content {
  a {
    color: rgba(0,0,0,.87);
    text-decoration: none;
  }
}

.breakWord {
  overflow-wrap: break-word;
}
</style>
