import Vue from 'vue';

Vue.filter('fullDate', (date) => {
  const dateOptions = {
    year: 'numeric', month: 'numeric', day: 'numeric',
  };
  const timeOptions = {
    hour: '2-digit', minute: '2-digit', hour12: false,
  };
  const newDate = new Date(date);
  return `${newDate.toLocaleTimeString('fr-FR', timeOptions)} le \
    ${newDate.toLocaleDateString('fr-FR', dateOptions)}`;
});

Vue.filter('sec', value => Math.round(parseInt(value, 10) / 100) / 10);

Vue.filter('capitalize', (value) => {
  if (!value) return '';
  const newValue = value.toString();
  return newValue.charAt(0).toUpperCase() + newValue.slice(1);
});
