import Vuex from 'vuex';
import auth from '@/store/modules/auth';
import snackbar from '@/store/modules/snackbar';
import documents from '@/store/modules/documents';
import documentFilters from '@/store/modules/document-filters';

export default new Vuex.Store({
  modules: {
    auth,
    snackbar,
    documents,
    documentFilters,
  },
});
