import '@babel/polyfill';
import Vue from 'vue';
import './plugins/vue-socket-io';
import './plugins/vuetify';
import './plugins/vuex';
import './filters/filters';
import App from './App.vue';
import store from './store';
import router from './router';

Vue.config.productionTip = false;

new Vue({
  store,
  router,
  render: h => h(App),
}).$mount('#app');
