/* eslint-disable no-param-reassign */
/* eslint-disable no-shadow */

const state = () => ({
  sort: 'createdAt:desc',
  tags: [],
  types: [],
  format: '',
  status: ['ERROR', 'PENDING', 'COMPLETE'],
  test: false,
  limit: 20,
});

const getters = {
  getFiltersAsURLParams: (state) => {
    const cleanFilters = {};
    if (state.tags.length > 0) {
      cleanFilters.tags = state.tags.join(',');
    }
    if (state.types.length > 0) {
      cleanFilters.types = state.types.join(',');
    }
    if (state.sort !== '') {
      cleanFilters.sort = state.sort;
    }
    if (state.format !== '') {
      cleanFilters.format = state.format;
    }
    if (state.test) {
      cleanFilters.test = true;
    }
    if (state.status.length > 0 && state.status.length < 3) {
      cleanFilters.status = state.status.join(',');
    }
    if (state.limit > 0 && state.limit <= 50) {
      cleanFilters.limit = state.limit;
    }
    return cleanFilters;
  },
};

const actions = {
};

const mutations = {
  setTags: (state, payload) => {
    state.tags = payload;
  },
  setTypes: (state, payload) => {
    state.types = payload;
  },
  addTag: (state, payload) => {
    if (!state.tags.includes(payload)) {
      state.tags.push(payload);
    }
  },
  addType: (state, payload) => {
    if (!state.types.includes(payload)) {
      state.types.push(payload);
    }
  },
  setSort: (state, payload) => {
    state.sort = payload;
  },
  setStatus: (state, payload) => {
    state.status = payload.length === 0 ? ['ERROR', 'PENDING', 'COMPLETE'] : payload;
  },
  setFormat: (state, payload) => {
    state.format = payload;
  },
  setTest: (state, payload) => {
    state.test = payload;
  },
  setLimit: (state, payload) => {
    state.limit = payload;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
