<template>
  <v-container fluid>
    <v-slide-y-transition mode="out-in">
      <v-layout column align-center>
        <div v-if="authenticated" class="text-xs-center mb-5">
          <p>Vous êtes authentifié. Vous pouvez consulter vos documents:</p>
          <v-btn outline color="primary" :to="{path: 'list'}">Consulter mes documents</v-btn>
        </div>
        <img src="@/assets/logo.png" class="mb-5">
        <blockquote>
          &#8220;First, solve the problem. Then, write the code.&#8221;
          <footer>
            <small>
              <em>&mdash;John Johnson</em>
            </small>
          </footer>
        </blockquote>
      </v-layout>
    </v-slide-y-transition>
  </v-container>
</template>

<script>
export default {
  name: 'App',
  computed: {
    authenticated() { return this.$store.getters['auth/isLoggedIn']; },
  },
};
</script>
<style scoped>
h1, h2 {
  font-weight: normal;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
