import Api from '@/services/API';

export default {
  getDocuments(params) {
    return Api().get('/documents', { params });
  },
  generateDocument(document) {
    return Api().post('/generate/pdf', { ...document });
  },
  deleteDocument(document) {
    return Api().delete(`/documents/${document._id}`); // eslint-disable-line
  },
  convertDocument(params) {
    return Api().post('/converter', params);
  },
};
