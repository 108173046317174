/* eslint-disable no-param-reassign */
/* eslint-disable no-shadow */
import documentService from '@/services/document.service';

const state = {
  documents: [],
  newDocument: '',
};

const getters = {
  getDocuments: state => state.documents,
};

const actions = {
  getDocuments: ({ commit }, params) => new Promise((resolve, reject) => {
    documentService.getDocuments(params)
      .then((res) => {
        commit('setDocuments', res.data.docs);
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  }),
  generateDocument: ({ commit }, document) => new Promise((resolve, reject) => {
    documentService.generateDocument(document)
      .then((res) => {
        commit('addDocument', res.data);
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  }),
  deleteDocument: ({ commit }, document) => new Promise((resolve, reject) => {
    documentService.deleteDocument(document)
      .then((res) => {
        commit('deleteDocument', document);
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  }),
  convertDocument: ({ commit }, params) => new Promise((resolve, reject) => {
    documentService.convertDocument(params)
      .then((res) => {
        commit('convertDocument', res.data);
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  }),
};

const mutations = {
  addDocument: (state, payload) => {
    state.documents.push(payload);
  },
  setDocuments: (state, payload) => {
    state.documents = payload;
  },
  deleteDocument: (state, payload) => {
    state.documents.splice(payload, 1);
  },
  convertDocument: (state, payload) => {
    state.documents.push(payload);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
