
<template>
  <v-container>
    <v-form ref="form" v-model="valid" lazy-validation>
      <v-layout row justify-center>
        <v-flex xs12 sm6 md4>
          <v-text-field
            label="API Key"
            v-model="APIKey"
            :rules="APIKeyRules"
            required
          >
          </v-text-field>
        </v-flex>
      </v-layout>
      <v-layout row justify-center>
        <v-btn @click="checkAPIKey" color="primary" :disabled="!valid">Se connecter</v-btn>
      </v-layout>
    </v-form>
  </v-container>
</template>

<script>
import { mapMutations } from 'vuex';

export default {
  name: 'login',
  data: () => ({
    valid: true,
    APIKeyRules: [
      v => !!v || 'L\'API Key est requise.',
    ],
    APIKey: null,
  }),
  methods: {
    checkAPIKey() {
      if (this.$refs.form.validate()) {
        const { APIKey } = this;
        this.$store.dispatch('auth/checkAPIKey', APIKey).then((client) => {
          if (client) {
            this.setSnack('API Key valide, l\'application est maintenant en mode authentifiée');
            this.$router.push('/');
          } else {
            this.setSnack('API Key invalide.');
            this.$refs.form.reset();
          }
        }).catch(() => {
          this.setSnack('Erreur inconnue.');
        });
      }
    },
    ...mapMutations({
      setSnack: 'snackbar/setSnack',
    }),
  },
};
</script>
