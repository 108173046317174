import Login from '@/views/Login.vue';
import Home from '@/views/Home.vue';

export default [
  {
    path: '/',
    name: 'home',
    component: Home,
  },
  {
    path: '/login',
    name: 'login',
    component: Login,
  },
  {
    path: '/list',
    name: 'list',
    component: () => import(/* webpackChunkName: "list" */ '@/views/List.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/new',
    name: 'new',
    component: () => import(/* webpackChunkName: "new" */ '@/views/New.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/convert',
    name: 'convert',
    component: () => import(/* webpackChunkName: "convert" */ '@/views/Convert.vue'),
    meta: {
      requiresAuth: true,
    },
  },
];
